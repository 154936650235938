.Work {
  background-color: white;
  margin-bottom: 100px;
  height: 55vh;
}

.Work-title {
  padding: 0 20%;
}

.Work-container {
  display: flex;
  flex-direction: row;
  padding: 0 20%;
  margin-top: 60px;
}

@media (max-width: 1300px){
  .Work {
    height: 100%;
  }

  .Work-container {
    padding: 0 5%;
  }
}
