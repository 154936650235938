.Touch {
  background-color: #282c34;
  padding: 50px 20%;
}

.Touch-text {
  color: white;
  margin-top: 50px;
  font-size: 19px;
}

.Touch-div-button {
  margin: 60px 0 180px 0;
}

.Touch-button-link {
  text-decoration: none;
  font-size: 29px;
}

.Touch-by {
  font-size: 14px;
  color: white;
}

@media (max-width: 700px){
  .Touch {
    padding: 20px 10%;
  }
 
  .Touch-div-button {
    margin: 60px 0 100px 0;
  }
}
