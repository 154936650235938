.Me {
  background-color: white;
  margin-bottom: 100px;
}

.Me-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 20%;
  margin-top: 80px;
}

.Me-body {
  flex: 5;
  margin-right: 50px;
}

.Me-image {
  flex: 3;
}

.Me-text {
  font-size: 19px;
  color: #1a1a1a;
  text-align: start;
}

.Me-subtitle {
  margin: 50px 0 30px 0;
}

.Me-image-profile {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 1300px){

  .Me-container {
    flex-direction: column;
    margin-top: 50px;
    padding: 0 10%;
  }

  .Me-body {
    flex: 1;
    margin-right: 0px;
  }

  .Me-image {
    flex: 1;
  }
}
