.TabPanel-role {
  display: flex;
  flex-direction: row;
  text-align: start;
}

.TabPanel-h2 {
  margin-bottom: 5px;
}

.TabPanel-company {
  color: #5b9bbc;
  margin-left: 10px;
}

.TabPanel-date {
  text-align: start;
}

.TabPanel-goal {
  text-align: start;
  font-size: 19px;
  color: #1a1a1a;
  margin-bottom: 5px;
}

.TabPanel-goal-container {
  width: 800px;
}

.TabPanel-link:link {
  text-decoration: none;
}