.Header {
  background-color: #282c34;
  display: flex;
  justify-content: center;
}

.section {
  background-color: #282c34;
  display: block;
  font-size: .938em;
  letter-spacing: .1em;
  color: #5b9bbc;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 20px;
  text-transform: uppercase;
}

.section:hover {
  color: white;
  cursor: pointer;
}

.header-container{
  background-color: #282c34;
  flex-direction: row;
  display: flex;
  height: 7vh;
  align-items: center;
}

@media (max-width: 700px){
  .section {
    margin-left: 15px;
    margin-right: 15px;
  }
  
}