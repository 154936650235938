.Skills {
  background-color: #eee;
  padding-bottom: 100px;
  padding: 1% 20% 5% 20%;
}

.Skills-main {
  display: flex;
  flex-direction: row;
  margin: 80px 0 50px 0;
  justify-content: space-around;
}

.Skills-h4 {
  font-size: 1em;
  color: #222;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.Skills-h3 {
  font-size: 1.5em;
  color: #222;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.Skills-frameworks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Skills-conatiner-tools {
  text-align: start;
}

@media (max-width: 800px){
  .Skills-frameworks {
    flex-direction: column;
  }

  .Skills-main {
    flex-direction: column;
    margin: 10px 0;
  }
}
