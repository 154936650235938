.HeaderTitle {
  margin-top: 80px;
}

.header-title {
  margin: 0 0 15px;
  padding: 0;
  font-size: 2.25em;
  font-weight: 300;
  color: #0f1025;
  text-transform: uppercase;
  display: block;
  text-align: center;
  letter-spacing: .1em;
}

.centered-line {
  margin-left: auto;
  margin-right: auto;
  float: none;
  display: block;
  width: 290px;
  height: 3px;
  padding: 0!important;
  background: red;
}

@media (max-width: 700px){
  .centered-line {
    width: 190px;
  }
}
