.BarTabs-role {
  display: flex;
  flex-direction: row;
  text-align: start;
}

.BarTabs-h2 {
  margin-bottom: 5px;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.BarTabs-company {
  color: #5b9bbc;
  margin-left: 10px;
}

.BarTabs-date {
  text-align: start;
}

.BarTabs-goal {
  text-align: start;
  font-size: 19px;
  color: #1a1a1a;
  margin-bottom: 5px;
}

.BarTabs-goal-container {
  width: 100%;
}

.BarTabs-link:link {
  text-decoration: none;
}