.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-page {
  height: 90vh;
}

.sticky-thc {
  position: sticky;
  top: 0;
}

.social-media {
	position: fixed;
	bottom: 40px;
	left: 40px;
  z-index: 1;
}
