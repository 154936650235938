.SocialMedia {
  display: flex;
  flex-direction: column;
}

.SocialMedia-icon {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  color: #5b9bbc;
  cursor: pointer;
}

.SocialMedia-icon:hover {
  top: -10px;
}
